* {
    margin: 0;
    padding: 0;
}

body {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333333;
    letter-spacing: 1px;
    line-height: 1.75;
    background-color: #F0F4F8;
    font-size: 110%;
}

