.card {
    margin: 10px auto;
    width: 45%;
    min-width: 500px;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
    display: flex;
    object-fit: contain;
}

.card img {
    border-right: #1E3A8A 2px solid;
    border-radius: 20px;
    width: 30%;
    object-fit: contain;
}

.cardInfo {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.cardInfo h2 {
    text-align: center;
    margin: 10px auto;
}

.cardInfo h3 {
    text-align: center;
    font-style: italic;
    color: #DAA520;
}

.underline {
    width: 100%;
    border-bottom: #1E3A8A solid 2px;
}

.buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 1rem;
    padding-bottom: 10px;
    padding-right: 10px;
}

.buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.moreBtn {
    width: 40%; 
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    color: #333333;
    border: #1E3A8A solid 2px;
    border-radius: 20px;
    transition: background-color 0.2s linear;
}

.moreBtn:hover {
    color: #FFFFFF;
    background-color: #1E3A8A;
}

.tgBtn {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    color: #0088cc;
    transition: background-color 0.2s linear;
}

.tgBtn:hover {
    color: #FFFFFF;
    background-color: #0088cc;
}