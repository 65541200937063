.content {
    margin: 0 auto;
    width: 90vw;
}

.content h1, h2 {
    text-align: center;
    margin: 10px auto;
}

.cartContainer {
    display: flex;
    justify-content: space-between;
}

.ulWrapper {
    width: 70%;
    list-style-type: none;
}

.inCartWrapper {
    text-align: center;
    width: 25%;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
}

.buyBtn {
    margin: 10px 0;
    padding: 10px 0;
    width: 20vw;
    cursor: pointer;
    border: #F0F4F8 2px solid;
    border-radius: 20px;
    font-size: 110%;
    background-color: #FFC107;
    color: #FFFFFF;
    transition: background-color 0.2s linear;
}

.buyBtn:hover {
    background-color: #F0F4F8;
    color: #FFC107;
    border: #FFC107 2px solid;
}

.clearBtnContainer {
    margin: 10px 0;
    display: flex;
    flex-direction: row-reverse;
}

.clearBtn {
    cursor: pointer;
    padding: 10px 10px;
    font-size: 110%;
    color: #333333;
    background-color: #F0F4F8;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
    transition: background-color 0.2s linear;
}

.clearBtn:hover {
    background-color: #1E3A8A;
    color: #FFFFFF;
}