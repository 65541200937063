.navbar {
    box-sizing: border-box;
    background-color: #1E3A8A;
    width: 100%;
    height: 3vw;
    min-height: 2.5rem;
    margin: 0 auto;
    padding: 0 5vw;
    position: sticky;
    display: flex;
    gap: 1rem;
}

.link {
    text-decoration: none;
    font-size: 1.5rem;
    color: #B0C4DE;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 80%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
}

.link:hover {
    color: #FFFFFF;
    background-size: 100% 2px;
}

.activeLink {
    color: #FFFFFF;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    height: 2.5vw;
    min-height: 2.5rem;
}

.shoppingCart {
    margin-left: auto;
}

.shoppingCartLogo {
    width: 2rem;
    height: 2rem;
}

.button {
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.75;
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: #B0C4DE;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background-color: #1E3A8A;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 80%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    border: none;
    transition: background-size .3s;
    letter-spacing: 1px;
}

.button:hover {
    color: #FFFFFF;
    background-size: 100% 2px;
}