.container {
    width: 100%;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
    padding: 0 1vw;
    margin: 10px auto;
    display: flex;
}

.countContainer {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.countContainer input {
    font-size: 110%;
    width: 30px;
    text-align: center;
    color: #333333;
    background-color: #F0F4F8;
    border: none;
    border-bottom: #1E3A8A 2px solid;
}

.price {
    margin-right: 20px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapperBtn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperIcon {
    font-size: 2rem;
    color: #1E3A8A;
    border-radius: 50%;
    transition: background-color 0.2s linear;
}

.wrapperIcon:hover {
    color: #fff;
    background-color: #1E3A8A;
}