.content {
    width: 90vw;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #FFE4B5;
}

.card {
    margin: 3rem auto;
    display: flex;
    border: #FF4500 4px solid;
    border-radius: 20px;
}

.card img {
    border-radius: 20px;
    width: 30%;
    object-fit: fill;
    border-right: #FF4500 4px solid;
}

.cardInfo {
    width: 70%;
}

.cardInfo h1 {
    text-align: center;
    margin: 20px auto;
}

.cardInfoMore {
    width: 90%;
    margin: 20px auto;
}

.cardInfoMore h3 {
    text-align: right;
    font-style: italic;
    color: #FF4500;
}

.cardInfoMore p {
    margin: 10px 0;
    text-align: justify;
}

.underline {
    width: 100%;
    border-bottom: #FF4500 4px solid;
}