.content {
    margin: 0 auto;
    width: 90vw;
}

.content h1, .content h3 {
    text-align: center;
    margin: 10px auto;
}

.content p {
    margin: 5px auto;
    text-align: justify;
}

.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enjoyBtn {
    margin: 10px auto;
    width: 20vw;
    min-width: 15rem;
    height: 7vw;
    min-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    background-color: #FFC107;
    color: #FFFFFF;
    animation: pulse 2s ease-in-out infinite;
}

.enjoyBtn:hover {
    animation: pulse-paused 0.2s forwards;
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    50% {
        transform: scale(.85);
    }
    to {
        transform: scale(1);
    }
}

@keyframes pulse-paused {
    0% {
        transform: scale(.85);
    }
    100% {
        transform: scale(1);
    }
}