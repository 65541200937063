.card {
    width:calc( ( 100% - 40px ) / 3 );
    margin: 3rem auto;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.card h1 {
    text-align: center;
    margin: 10px auto;
}

.underline {
    width: 100%;
    border-bottom: #1E3A8A 2px solid;
}

.card ul {
    width: 90%;
    margin: 0 auto;
    list-style: none;
}

.card li {
    border-bottom: #cccccc 2px solid;
}

.checkIcon {
    color: #333333;
}

.buyBtnWrapper {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 10px;
    gap: 1rem;
}

.buyBtnWrapper h3 {
    line-height: 5rem;
}

.buyBtn {
    width: 30%;
    height: 5rem;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.2s linear;
}

.buyBtn:hover {
    color: #FFFFFF;
    background-color: #1E3A8A;
}

.clicked {
    color: #FFFFFF;
    background-color: #1E3A8A;
}

.unClicked {
    color: #333333;
    background-color: #F0F4F8;
}