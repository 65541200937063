.content {
    margin: 0 auto;
    width: 90vw;
}

.content h1 {
    text-align: center;
    margin: 10px auto;
}

.cards {
    display: flex;
}