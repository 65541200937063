.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Убедитесь, что модальное окно поверх других элементов */
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 450px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modalText {
    color: #000000;
}

.link {
    text-decoration: none;
    font-size: 1.5rem;
    color: #000000;
    display: flex;
    align-items: left;
}

.link:hover {
    color: #0084ff;
    background-size: 100% 2px;
}