.content {
    width: 90vw;
    margin: 0 auto;
}

.card {
    margin: 3rem auto;
    display: flex;
    border: #1E3A8A 2px solid;
    border-radius: 20px;
}

.card img {
    border-radius: 20px;
    width: 30%;
    object-fit: contain;
    border-right: #1E3A8A 2px solid;
}

.cardInfo {
    width: 70%;
}

.cardInfo h1 {
    text-align: center;
    margin: 20px auto;
}

.cardInfoMore {
    width: 90%;
    margin: 20px auto;
}

.cardInfoMore h3 {
    text-align: right;
    font-style: italic;
    color: #DAA520;
}

.cardInfoMore p {
    text-align: justify;
}

.underline {
    width: 100%;
    border-bottom: #1E3A8A 2px solid;
}

.subs {
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}