.content {
    width: 90vw;
    margin: 0 auto;
}

.content h1 {
    text-align: center;
    margin: 10px auto;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-end;
}